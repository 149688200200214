/* Using grid and margin: auto for a vert/hori centered container */

.container {
  /*
  Apparently, Bootstrap does not come with built-in styles for Grid displays
  https://getbootstrap.com/docs/4.0/utilities/display/
  */
  display: grid;
}

.card {
  margin: auto;
}

.userAgreement {
  height: 200px;
  overflow: scroll;
}
