@import 'colors.module';

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.success {
  color: $success;
}

.warning {
  color: $warning;
}

.danger {
  color: $danger;
}

.info {
  color: $info;
}

.light {
  color: $light;
}

.dark {
  color: $dark;
}

/* Sizes */

.large {
  font-size: 2em;
}

.larger {
  font-size: 3.5em;
}

/* Modifiers */

.primary-bg {
  background-color: $primary;
}

.secondary-bg {
  background-color: $secondary;
}

.success-bg {
  background-color: $success;
}

.warning-bg {
  background-color: $warning;
}

.danger-bg {
  background-color: $danger;
}

.info-bg {
  background-color: $info;
}

.light-bg {
  background-color: $light;
}

.dark-bg {
  background-color: $dark;
}

.circle {
  color: white;
  border-radius: 100%;
  display: inline-block;
  height: 2em;
  width: 2em;
  text-align: center;
}
