/* Keep these sizes 5em smaller than cards */

.sm {
  max-width: 20em;
}

.md {
  max-width: 35em;
}

.lg {
  max-width: 55em;
}
