.base {
  width: 100%;
}

/* Keep these sizes 5em larger than modals */
.sm {
  max-width: 25em;
}

.md {
  max-width: 40em;
}

.lg {
  max-width: 60em;
}
